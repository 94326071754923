<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import Swal from "sweetalert2";
import errorHandling from "../../../mixins/errorHandling";

/**
 * Product Detail Component
 */
export default {
  mixins: [errorHandling],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      paymnentTypeId: "1",
      options: [
        { text: "Cash", value: "1" },
        { text: "Momo", value: "2" },
      ],
      title: "Verify",
      items: [
        {
          text: "Agent",
        },
        {
          text: "Verify",
          active: true,
        },
      ],
      customer: "",
      customerImage: "",
      savingsData: [],
      submitted: false,
      name: "",
      loanName: "",
      phone: "",
      amount: "",
      loading: false,
      paying: false,
      showmodal: false,
      momoNumber: "",
      selectedMomo: "",
    };
  },
  validations: {
    name: { required },
    loanName: { required },
    phone: { required },
    amount: { required },
  },
  methods: {
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.showmodal = false;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Selected image shows
     */
    imageShow(event) {
      const image = event.target.src;
      const expandImg = document.getElementById("expandedImg");
      expandImg.src = image;
    },
    async getCustomer() {
      this.loading = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/agent/get-one-customer-by-id/${this.$route.params.id}`
        );
        if (response.status === 200 || response.status === 201) {
          this.customer = response.data.data;
          this.name = response.data.data.displayName;
          this.phone = response.data.data.mobileNo;
          this.loading = false;
        } else {
          this.loading = false;
          this.throwError();
        }
      } catch (error) {
        this.handleError(error.response.data.message);
      }
    },
    async getCustomerSavings() {
      this.loading = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/agent/get-customer-savings/${this.$route.params.savingsID}`
        );
        if (response.status === 200 || response.status === 201) {
          this.savingsData = response.data.data;
          this.loanName = response.data.data.savingsProductName;
          this.loading = false;
        } else {
          this.loading = false;
          this.throwError();
        }
      } catch (error) {
        this.handleError(error.response.data.message);
      }
    },

    async getCustomerImage() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/agent/get-customer-image/${this.$route.params.id}`
        );
        if (response.status === 200 || response.status === 201) {
          this.customerImage = response.data.data;
        } else {
          this.throwError();
        }
      } catch (error) {
        this.handleError("Error fetching customer image");
      }
    },
    async confirmMomoPayment(transactionID) {
      try {
        const confirmationResult = await Swal.fire({
          title: "Confirm Payment",
          text: "Please WAIT!! let the customer enter PIN before you confirm",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes, confirm!",
        });

        if (confirmationResult.isConfirmed) {
          this.paying = true;
          const url = `${
            process.env.VUE_APP_BASE_URL
          }/agent-transaction/make-deposit-savings/momo-verify/${
            this.$route.params.savingsID
          }/${localStorage.getItem("id")}/${transactionID}`;
          const response = await axios.get(url);

          if (response.status === 200 || response.status === 201) {
            this.paying = false;
            await Swal.fire(
              "Success!",
              "Loan Paid, Verification Sent",
              "success"
            );

            // Redirect after Swal is closed
            window.location = "/agent/transactions";
          } else {
            this.paying = false;
            Swal.fire("Error!", "An error occurred!", "error");
          }
        }
      } catch (error) {
        this.paying = false;
        Swal.fire("Error!", "An error occurred!", "error");
      }
    },
    async momoPayment() {
      if (this.momoNumber[0] === "0") {
        this.momoNumber = this.momoNumber.replace(this.momoNumber[0], "233");
      }
      try {
        this.paying = true;
        const url = `${
          process.env.VUE_APP_BASE_URL
        }/agent-transaction/make-deposit-savings/momo/${
          this.$route.params.savingsID
        }/${localStorage.getItem("id")}`;
        const response = await axios.post(url, {
          amount: parseFloat(this.amount),
          paymentTypeId: this.paymnentTypeId,
          customerPhoneNumber: this.phone,
          customerName: this.name,
          transactionPaymentType: "Individual",
          mobileNetwork: this.selectedMomo,
          momoNumber: this.momoNumber,
        });

        if (response.status === 200 || response.status === 201) {
          this.confirmMomoPayment(response.data.transaction._id);
          this.paying = false;
        } else {
          this.paying = false;
          Swal.fire("Error!", "An error occurred!", "error");
        }
      } catch (error) {
        this.paying = false;
        Swal.fire("Error!", "An error occurred!", "error");
      }
    },
    async verifyPayment() {
      if (this.paymnentTypeId === "1") {
        try {
          const confirmationResult = await Swal.fire({
            title: "Are you sure?",
            text: "Please confirm this action",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes, confirm!",
          });

          if (confirmationResult.isConfirmed) {
            this.paying = true;
            const url = `${
              process.env.VUE_APP_BASE_URL
            }/agent-transaction/make-deposit-savings/${
              this.$route.params.savingsID
            }/${localStorage.getItem("id")}`;
            const response = await axios.post(url, {
              transactionAmount: this.amount,
              paymentTypeId: this.paymnentTypeId,
              customerPhoneNumber: this.phone,
              customerName: this.name,
              transactionPaymentType: "Individual",
            });

            if (response.status === 200 || response.status === 201) {
              this.paying = false;
              await Swal.fire(
                "Success!",
                "Savings Paid, Verification Sent",
                "success"
              );

              // Redirect after Swal is closed
              window.location = "/agent/transactions";
            } else {
              this.paying = false;
              Swal.fire("Error!", "An error occurred!", "error");
            }
          }
        } catch (error) {
          this.paying = false;
          Swal.fire("Error!", error.response.data.defaultUserMessage, "error");
        }
      } else if (this.paymnentTypeId === "2") {
        this.showmodal = true;
      } else {
        console.log("Invalid Option");
      }
    },
  },
  async mounted() {
    this.getCustomer();
    this.getCustomerSavings();
    this.getCustomerImage();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="loading">
      <b-spinner class="m-2 m-auto" variant="primary" role="status"></b-spinner>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="col-12 m-auto p-4">
            <div class="row">
              <img
                id="expandedImg"
                :src="customerImage"
                width="200"
                class="img-fluid mx-auto d-block"
              />
            </div>

            <!-- end product img -->
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-12">
                <div class="mt-4 mt-xl-3">
                  <p href="#" class="text-primary font-size-24 text-center">
                    Cash Collateral Verification
                  </p>

                  <div class="row">
                    <div class="col-md-4">
                      <div class="card">
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body overflow-hidden">
                              <p class="text-truncate font-size-14 mb-2">
                                Customer Name
                              </p>
                              <h4 class="mb-0">{{ customer.displayName }}</h4>
                            </div>
                            <div class="text-primary">
                              <i class="ri-user-3-line font-size-24"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="card">
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body overflow-hidden">
                              <p class="text-truncate font-size-14 mb-2">
                                Phone Number
                              </p>
                              <h4 class="mb-0">{{ customer.mobileNo }}</h4>
                            </div>
                            <div class="text-primary">
                              <i class="ri-phone-line font-size-24"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="card">
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body overflow-hidden">
                              <p class="text-truncate font-size-14 mb-2">
                                Account Number
                              </p>
                              <h4 class="mb-0">{{ customer.accountNo }}</h4>
                            </div>
                            <div class="text-primary">
                              <i class="ri-hashtag font-size-24"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="col-lg-12"></div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <div class="card">
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body overflow-hidden">
                              <p class="text-truncate font-size-14 mb-2">
                                Savings Account / Account Balance
                              </p>
                              <h4 class="mb-0">
                                GHS {{ savingsData.summary.availableBalance }}
                              </h4>
                            </div>
                            <div class="text-primary">
                              <i class="ri-user-3-line font-size-24"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Payment Verification</h4>
                      <p class="card-title-desc">
                        Enter the customers number below to send a verification
                        SMS to verify payment
                      </p>
                      <form
                        class="needs-validation"
                        @submit.prevent="formSubmit"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="validationCustom01">Name</label>
                              <input
                                id="validationCustom01"
                                v-model="name"
                                disabled
                                type="text"
                                class="form-control"
                                placeholder="Name"
                                value="Mark"
                                :class="{
                                  'is-invalid': submitted && $v.name.$error,
                                }"
                              />
                              <div
                                v-if="submitted && $v.name.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.name.required"
                                  >This value is required.</span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="validationCustom02"
                                >Savings Name</label
                              >
                              <input
                                id="validationCustom02"
                                v-model="loanName"
                                disabled
                                type="text"
                                class="form-control"
                                placeholder="Loan name"
                                value="Name"
                                :class="{
                                  'is-invalid': submitted && $v.loanName.$error,
                                }"
                              />
                              <div
                                v-if="submitted && $v.loanName.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.loanName.required"
                                  >This value is required.</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="validationCustom03"
                                >Phone Number - (A verification SMS will be
                                sent)</label
                              >
                              <input
                                id="validationCustom03"
                                v-model="phone"
                                type="text"
                                disabled
                                class="form-control"
                                placeholder="055*******"
                                :class="{
                                  'is-invalid': submitted && $v.phone.$error,
                                }"
                              />
                              <div
                                v-if="submitted && $v.phone.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.phone.required"
                                  >This value is required.</span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="validationCustom04">Amount</label>
                              <input
                                id="validationCustom04"
                                v-model="amount"
                                type="text"
                                class="form-control"
                                placeholder="0.00"
                                :class="{
                                  'is-invalid': submitted && $v.amount.$error,
                                }"
                              />
                              <div
                                v-if="submitted && $v.amount.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.amount.required"
                                  >This value is required.</span
                                >
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <b-form-group
                              label="Payment Type (What is the customer paying with ?)"
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio-group
                                id="btn-radios-1"
                                v-model="paymnentTypeId"
                                :options="options"
                                button-variant="outline-success"
                                :aria-describedby="ariaDescribedby"
                                name="radios-btn-default"
                                buttons
                                class="custom-radio-group"
                              ></b-form-radio-group>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row"></div>
                        <b-button
                          variant="primary"
                          size="lg"
                          @click.prevent="verifyPayment"
                        >
                          <i class="ri-check-double-line align-middle mr-2"></i>
                          Verify Payment
                          <span
                            class="spinner-border mb-1 ml-2 spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                            v-if="paying"
                          ></span>
                        </b-button>
                      </form>
                    </div>
                  </div>
                  <!-- end card -->

                  <hr class="my-4" />
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
    <!-- Modal -->
    <b-modal
      centered
      v-model="showmodal"
      title="Pay with Momo"
      title-class="text-dark font-18 text-center"
      hide-footer
    >
      <form>
        <div class="custom-control custom-radio mb-3">
          <input
            type="radio"
            id="customRadio1"
            value="MTN"
            name="momo"
            v-model="selectedMomo"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio1">MTN</label>
        </div>

        <div class="custom-control custom-radio mb-3">
          <input
            type="radio"
            id="customRadio2"
            value="VDF"
            name="momo"
            v-model="selectedMomo"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio2"
            >Vodafone</label
          >
        </div>
        <div class="custom-control custom-radio mb-3">
          <input
            type="radio"
            id="customRadio3"
            value="ATL"
            name="momo"
            v-model="selectedMomo"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio3">Airtel</label>
        </div>
        <div class="custom-control custom-radio mb-3">
          <input
            type="radio"
            id="customRadio4"
            value="TGO"
            name="momo"
            v-model="selectedMomo"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio4">Tigo</label>
        </div>
        <div class="custom-control custom-radio mb-3">
          <input
            type="radio"
            id="customRadio5"
            value="ZPY"
            name="momo"
            v-model="selectedMomo"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio5">Zeepay</label>
        </div>
        <div class="custom-control custom-radio mb-3">
          <input
            type="radio"
            id="customRadio6"
            value="GMY"
            name="momo"
            v-model="selectedMomo"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio6">Gmoney</label>
        </div>
        <div class="form-group">
          <label for="company">Momo Number</label>
          <input
            id="company"
            v-model="momoNumber"
            type="text"
            class="form-control"
            placeholder="Enter Momo Number"
          />
        </div>
        <div class="text-center">
          <button
            type="submit"
            class="btn btn-success"
            @click.prevent="momoPayment"
          >
            Send Promt
          </button>
          <b-button class="ml-1" variant="secondary" @click="hideModal"
            >Cancel</b-button
          >
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>

<style scoped>
.custom-radio-group {
  width: 100%; /* Adjust the width value as per your requirement */
}
</style>
